
export function getError() {
  const num = Math.floor(Math.random() * 10);
  let val = '';
  let img = true;
  console.log(num);
  switch (num) {
    case 0:
      img = false;
      val = 'NOPE!';
      break;
    case 1:
      img = false;
      val = 'Try again!'
      break;
    case 2:
      val = <iframe title="no" src="https://giphy.com/embed/9dgnO4jts7kmsFcSPq"
                    width="480" height="480"></iframe>;
      break;
    case 3:
      val = <iframe title="no" src="https://giphy.com/embed/26tPbBRTZ8CQvyxeU"
                    width="480" height="360"></iframe>;
      break;
    case 4:
      img = false;
      val = 'Is that just a wild guess?';
      break;
    case 5:
      val = <iframe title="no" src="https://giphy.com/embed/VgqtLbNtJEWtVlfMVv"
                    width="421" height="480"></iframe>;
      break;
    case 6:
      val = <iframe title="no" src="https://giphy.com/embed/5T8tEJtCgvDuo"
                    width="480" height="476"></iframe>;
      break;
    case 7:
      val = <iframe title="no" src="https://giphy.com/embed/RQU6LwKmZheaDL13uA"
                    width="480" height="446"></iframe>;
      break;
    case 8:
      val = <iframe title="no" src="https://giphy.com/embed/q49YSnLzrvghiyKBAR"
              width="480" height="480"></iframe>;
      break;
    default:
      val = <iframe title="no" src="https://giphy.com/embed/eKrgVyZ7zLvJrgZNZn"
                    width="360" height="480"></iframe>;
      break;
  }

  if (img) {
    return <div className="error errImg">{val}</div>;
  }
  return <div className="error blink">{val}</div>;
}
