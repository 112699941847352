import {useEffect, useState} from "react";
import {getError} from "./utils";


export default function Quiz({clue, hint, answer, success}) {
  const [error, setError] = useState(null);
  const [img, setImg] = useState(null);

  useEffect(() => {
    document.getElementById('q').value = '';
    setImg(clue);
  }, [clue])

  const submitHandler = (ev) => {
    const value = document.getElementById('q').value || '';
    if (value.toLowerCase() === answer) {
      success();
      setError(null);
    } else {
      setError(getError());
    }
    ev.preventDefault();
  }
  const showHelp = () => {
    setImg(hint);
  }

  return (
      <>
        <header>
          <img src={img} alt="clue" />
        </header>
        <form onSubmit={submitHandler}>
          <div>
            <label htmlFor="q">Answer:</label>&nbsp;
            <input id="q" name="result" type="text" />&nbsp;
            <input type="submit" />
          </div>
          {error}
        </form>
        <button style={{marginTop: '3rem'}} onClick={showHelp}>i need help!</button>
      </>
  );
}
